"use client";
import { useEffect, useState } from "react";
import Loader from "@/components/common/Loader";
import "flatpickr/dist/flatpickr.min.css";
import "@/css/satoshi.css";
import "@/css/style.css";
import { useSession } from "next-auth/react";
import { usePathname, useRouter } from "next/navigation";

export default function TestLayout({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState<boolean>(true);
  const sessionResult = useSession();
  const session = sessionResult?.data;
  const status = sessionResult?.status || "loading";
  const router = useRouter();
  const pathname = usePathname() || ""; 
  const authRoutes = ["/auth/signin", "/auth/signup"];

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    if (status === "unauthenticated" && !authRoutes.includes(pathname)) {
      router.push("/auth/signin");
    }
  }, [status, session, pathname, router, authRoutes]);

  if (loading) {
    return <Loader />;
  }

  if (status === "loading") {
    return <Loader />; // Or any other loading indicator
  }

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark">
      {children}
    </div>
  );
}
